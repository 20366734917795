import React, { useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const Contact: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
  
    emailjs
      .sendForm('service_deysn8i', 'template_op1gxgk', form, '0MQVosW4Ns1Sn88it')
      .then(
        (result) => {
          setSuccessMsg('Message sent successfully!');
          form.reset();
          setErrorMsg('');
        },
        (error) => {
          setErrorMsg('Failed to send message. Please try again later.');
        }
      );
  };

  const validateForm = (name: any, email: any, comments: any) => {
    if (!name || name.trim() === '') {
      return 'Please enter your name.';
    }
    if (!email || email.trim() === '') {
      return 'Please enter your email.';
    }
    if (!comments || comments.trim() === '') {
      return 'Please enter your comments.';
    }
    return '';
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  Get In Touch
                </h2>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-4">
            <Col lg={6}>
              <div className="startup-map mt-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99779.05760053509!2d81.80344030911931!3d25.4549703491531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398534c9b20bd49f%3A0xa2237856ad4041a!2sPrayagraj%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1726144494849!5m2!1sen!2sin"
                  height="350"
                  style={{ border: 0, width: '100%' }}
                  allowFullScreen
                  title="Google Map"
                ></iframe>
              </div>
            </Col>

            <Col lg={6}>
              <div className="custom-form mt-3">
              <form method="post" onSubmit={handleSubmit} className="contact-form" name="myForm" id="myForm">
  {errorMsg && <Alert variant="danger" className="error_message">{errorMsg}*</Alert>}
  {successMsg && <Alert variant="success" className="success_message">{successMsg}</Alert>}

  <Row>
    <Col lg={6}>
      <div className="form-group mt-3">
        <Form.Control name="from_name" id="from_name" type="text" placeholder="Your name" />
      </div>
    </Col>

    <Col lg={6}>
      <div className="form-group mt-3">
        <Form.Control name="from_email" id="from_email" type="email" placeholder="Your email" />
      </div>
    </Col>
  </Row>

  <Row>
    <Col lg={12}>
      <div className="form-group mt-3">
        <Form.Control as="textarea" name="message" id="message" rows={8} placeholder="Your message" />
      </div>
    </Col>
  </Row>

  <Row>
    <Col lg={12}>
      <div className="mt-3">
        <input type="submit" id="submit" name="send" className="submitBnt btn btn-dark-custom" value="Send Message" />
        <div id="simple-msg"></div>
      </div>
    </Col>
  </Row>
</form>
              </div>
            </Col>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Contact;
