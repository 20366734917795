import React from 'react'
import Section from './Section'
import Section1 from './Section1'
import Navbar8 from '../../layout/Navbar8'

const Index9 = () => {
  return (
    <React.Fragment>
        <Navbar8 />
        <Section />
        <Section1 />
    </React.Fragment>
  )
}

export default Index9