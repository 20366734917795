import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import Counter from '../../components/Counter';
import Testimonial from '../../components/Testimonial';
import Contact from '../../components/Contact';
import FooterSection from '../../layout/Footer';
import Pricing from '../../components/Pricing';
import Img_Component from "../../assets/images/background/OBJECTS.jpg"
import team1 from "../../assets/images/team/team-1.jpg"
import team2 from "../../assets/images/team/team-2.jpg"
import team3 from "../../assets/images/team/team-3.jpg"
import team4 from "../../assets/images/team/team-4.jpg"

interface Data {
    id: number; head: string; icon: string; description:string;
}

interface Feature {
    id: number; title: string;
}

interface TeamMembers {
    name: string; role: string; imageSrc: string;
}

const data: Data[] = [
    { id: 1, head: "Easy For Users", icon: "pe-7s-rocket", description:"At Divya Sangam, we specialize in creating custom websites for temples, restaurants, hotels, and other businesses in Prayagraj. Our mission is to empower local establishments with modern digital tools, helping them reach a broader audience and showcase their unique offerings during this significant spiritual gathering." },
    { id: 2, head: "Pixel Perfect", icon: "pe-7s-vector", description:"With a deep understanding of Prayagraj’s cultural significance and the transformative power of the Kumbh Mela, we are committed to building online platforms that reflect the essence of this ancient city. Whether it’s a temple seeking to connect with devotees, a restaurant aiming to attract pilgrims, or a hotel providing comfort to travelers, we ensure your presence is prominently felt in the digital world."  },
    { id: 3, head: "Edit Content", icon: "pe-7s-edit", description:"Let Divya Sangam be your partner in making Kumbh 2025 a memorable and successful experience for your business and the millions journeying to the confluence of the sacred rivers."  },
]

const feature: Feature[] = [
    { id: 1, title: "COUNTDOWN TIMER" },
    { id: 2, title: "PARALLAX" },
    { id: 3, title: "GOOGLE MAPS" },
]

const teamMembers: TeamMembers[] = [
    { name: 'Christopher Amaro', role: 'Designer', imageSrc: team1, },
    { name: 'Jeremy Palmateer', role: 'Founder', imageSrc: team2, },
    { name: 'Ruben Spradling', role: 'Manager', imageSrc: team3, },
    { name: 'Denny Strickland', role: 'Developer', imageSrc: team4, },
];

const Section1 = () => {
    return (
        <React.Fragment>
            {/* About */}
            <section className="section startup-about" id="about">
                <Container>
                <Row className="justify-content-center">
                        <Col lg={12}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">Why choose Divya Sangam</h2>
                                <p className="heading-title-desc text-muted mt-4">Welcome to Divya Sangam, where spirituality meets technology in the heart of Prayagraj. As the world prepares for Kumbh 2025, we are dedicated to helping the sacred city of Prayagraj showcase its rich heritage, vibrant culture, and essential services to millions of visitors from around the globe.</p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="text-center">
                                <p className="heading-title-desc text-muted mt-4">At Divya Sangam, we specialize in creating custom websites for temples, restaurants, hotels, and other businesses in Prayagraj. Our mission is to empower local establishments with modern digital tools, helping them reach a broader audience and showcase their unique offerings during this significant spiritual gathering.</p>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="mt-5 pt-4">
                        {
                            (data || [])?.map((item: Data, key: number) => (
                                <Col lg={4} key={key}>
                                    <div className="startup-about-box mt-4">
                                        <div className="startup-about-icon float-start">
                                            <i className={item.icon}></i>
                                        </div>
                                        <div className="startup-about-content ps-4 pt-2">
                                            <h4 className="f-15 letter-spacing_2 text-uppercase">{item.head}</h4>
                                            <p className="text-muted mt-3">{item.description}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row> */}
                </Container>
            </section>
             {/* <Counter /> */}
             <div style={{display:"flex",justifyContent:"center", width:"99vw"}}>
                <img src={Img_Component} alt='' style={{objectFit:"cover",width:"80vw",  maxWidth:"500px"}}/>
             </div>
            {/* Features */}
            <section className="section" id="features">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">Our Services</h2>
                                <p className="heading-title-desc text-muted mt-4">At Divya Sangam, we offer tailored website solutions to meet the diverse needs of businesses and organizations in Prayagraj. Whether you’re a temple, restaurant, or hotel, we have a plan designed to fit your requirements:</p>
                            </div>
                        </Col>
                    </Row>
                    <Pricing />
                </Container>
            </section>

            {/* Counter */}
           

            {/* Testimonial */}
            <Testimonial />

            <hr />

            {/* Pricing */}
           

            {/* CTA */}
            {/* <section className="section bg-stratup-cta">
                <div className="bg-overlay"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={9}>
                            <div className="startup-cta-box text-center text-white">
                                <h3 className="text-uppercase letter-spacing_4">
                                    Buy the startups now!
                                </h3>
                                <p className="f-15 mt-4">
                                    Dignissimos ducimus blanditiis praesentium voluptatum deleniti
                                    corrupti molestias.
                                </p>
                                <div className="mt-5 text-center">
                                    <Button href="#" className="btn btn-md btn-white">
                                        Buy Now!
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            {/* <section className="section" id="team">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                                    Smart Team
                                </h2>
                                <p className="heading-title-desc text-muted mt-4">
                                    Pellentesque habitant morbi tristique senectus malesuada fames
                                    turpis egestas duis dignissim finibus commodo nibh malesuada
                                    sollis diam.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <div className="row mt-5 pt-3">
                        {(teamMembers || [])?.map((member: TeamMembers, index: number) => (
                            <Col lg={3} key={index}>
                                <div className="startup-team-box text-center mt-4">
                                    <div className="startup-team-img">
                                        <Image src={member.imageSrc} alt="" fluid className="img-fluid" />
                                    </div>

                                    <div className="startup-team-content p-4 text-center">
                                        <h3 className="f-15 text-uppercase letter-spacing_2">
                                            {member.name}
                                        </h3>
                                        <p className="text-muted f-13 mb-0">{member.role}</p>

                                        <div className="mt-3">
                                            <ul className="list-inline startup-team-social mb-0">
                                                <li className="list-inline-item">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-facebook"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </div>
                </Container>
            </section> */}

            {/* <hr /> */}
            <Counter/>

            {/* Contact */}
            <Contact />

            {/* Footer */}
            <FooterSection />

        </React.Fragment>
    )
}

export default Section1