import React, { useState } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'

// Image
import user1 from "../assets/images/users/user-1.jpg";
import user2 from "../assets/images/users/user-2.jpg";
import user3 from "../assets/images/users/user-3.jpg";

interface TestData {
    id: number; image: string; author: string; post: string;desc:string;
}

interface IndicatorsData {
    text: string;
}

const testData: TestData[] = [
    { id: 1, image: user1, author: "Sarah M.", post: "General Manager",desc:"Working with Divvy Sangam Services has been a game-changer for our business. Their attention to detail and commitment to excellence are unmatched. From start to finish, their team provided exceptional support and innovative solutions that truly made a difference. I highly recommend their services to anyone looking for top-notch professional support." },
    { id: 2, image: user2, author: "Priya K", post: "Graphic Designer",
        desc:"Divvy Sangam Services has been a true asset to our company. Their proactive and creative approach helped us achieve our goals faster than we anticipated. Their exceptional service and insightful recommendations have made a remarkable impact. We are thrilled with their support and look forward to a long-lasting partnership."
     },
    { id: 3, image: user3, author: "Daniel H", post: "Web Developer",desc:"The team at Divvy Sangam Services is exceptional. They provided tailored solutions that addressed our specific needs and delivered impressive results. Their dedication and professional approach have been crucial in our growth journey. It’s been a pleasure working with them, and I wholeheartedly recommend their services." },
]

const Testimonial: React.FC = () => {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const handleSelect = (selectedIndex: any) => {
        setActiveIndex(selectedIndex);
    };

    const indicatorsData: IndicatorsData[] = [
        { text: "avatar-title rounded-circle" },
        { text: "avatar-title rounded-circle" },
        { text: "avatar-title rounded-circle" },
    ]

    const renderIndicators = () => {
        return (indicatorsData || [])?.map((item: IndicatorsData, index: number) => (
            <button type="button" data-bs-target="#carouselExampleIndicators" className={index === activeIndex ? 'active ' : ''}
                onClick={() => handleSelect(index)} key={index} >
                <div className="avatar-xs">
                    <div className={item.text}>
                    </div>
                </div>
            </button>
        ))
    };
    return (
        <React.Fragment>
            <section className="section gym-client" id="client">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">Our Testimonials</h2>
                                <p className="heading-title-desc text-muted mt-4">Our services stand out because we are committed to delivering excellence. From web development to digitalization, we ensure the highest quality, tailored solutions to meet the needs of our clients. Hear what others have to say about their experience with us!</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-5 pt-3">
                        <Col lg={9}>
                            <div className="carousel-indicators  d-flex">
                                {renderIndicators()}
                            </div>
                            <Carousel className='owl-carousel owl-theme' id="startup-owl-demo" activeIndex={activeIndex} onSelect={handleSelect} indicators={false}>
                                {(testData || [])?.map((item: TestData, key: number) => (
                                    <Carousel.Item key={key}>
                                        <div className="item" >
                                            <div className="startup-client-box text-center">
                                                <div className="photographer-desc">
                                                    <p className="photographer-desc f-16 text-muted mt-3">{item.desc}</p>
                                                </div>

                                                <div className="startup-client-img mt-5">
                                                    <img src={item.image} className="img-fluid rounded-circle" alt="" />
                                                </div>
                                                <h4 className="f-15 mt-4 letter-spacing_2 text-uppercase">- {item.author} <span className="f-12 text-muted">{item.post}</span></h4>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Testimonial