import React from 'react'
import Section from './Section'
import Navbar2 from '../../layout/Navbar2'

const Index3 = () => {
  return (
    <React.Fragment>
        <Navbar2 />
        <Section />
    </React.Fragment>
  )
}

export default Index3
