import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const FooterSection: React.FC = () => {
    return (
        <React.Fragment>
            <footer className="section agency-footer pb-5">
                <Container>
                    <Row>
                        <Col lg={3} md={4}>
                            <div>
                                <h6 className="footer-heading text-uppercase fw-bold f-13">Information</h6>
                                <ul className="list-unstyled footer-link mt-3 mb-0">
                                    <li>
                                        <Link to="">Pages</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Our Team</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Feuchers</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Pricing</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3} md={4}>
                            <div>
                                <h6 className="footer-heading text-uppercase fw-bold f-13">Help</h6>
                                <ul className="list-unstyled footer-link mt-3 mb-0">
                                    <li>
                                        <Link to="mailto:divyasangamsolutions@gmail.com">divyasangamsolutions@gmail.com </Link>
                                    </li>
                                    <li>
                                        <Link to="https://api.whatsapp.com/send?phone=9918233622">Whatsapp</Link>
                                    </li>
                                    <li>
                                    <Link to="tel:9918233622">+91 99182 33622</Link>
                                    </li>
                                    <li>
                                        <Link to="tel:9548941358">+91 95489 41358</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                       
                    </Row>

                    <Row className="mt-5">
                        <Col lg={12}>
                            <div className="agency-footer-alt-content p-4">
                                <p className="copy-right text-center mb-0">
                                    © {new Date().getFullYear()} Divya Sangam. Product by Hj & At
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default FooterSection;