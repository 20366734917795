import React, { useState } from 'react';

import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useScroll from '../../components/useScroll';
import useActiveLink from '../../components/useActiveLink';

const Section: React.FC = () => {
   // scroll page state call
   const navClass = useScroll();

   // activation
   const { activeLink, handleLinkClick } = useActiveLink('.navbar-nav li.active');

  // open+close modal state
  const [showModal, setShowModal] = useState<boolean>(false);
  // modal open
  const openModal = () => setShowModal(true);
  // modal close
  const closeModal = () => setShowModal(false);

  return (
    <React.Fragment>
      <section className="section startup-home" id="home">
        <video autoPlay muted loop id="background-video">
          <source src="/bg_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="bg-overlay"></div>
        <div className="home-center">
          <div className="home-desc-center">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="startup-home-content text-center text-white">
                    <h4 className="startup-home-title text-uppercase letter-spacing_4">Bridging Tradition & Technology for Kumbh Mela</h4>
                    <p className="startup-home-desc f-16 mt-4 mx-auto line-height_1_8 mb-0">As Kumbh Mela nears, Divya Sangam offers website development and digital solutions to connect with pilgrims.</p>
                    <div className="softwere-home-watch-video mt-5">
                   
                        {/* <i className="mdi mdi-play play-icon-circle play play-icon f-20"></i>
                        <p className="f-14 letter-spacing_2 text-uppercase mt-4 mb-0">WATCH OUR VIDEO</p> */}
                       
                        <Button  className="bg-warning">
                        <Link to='https://api.whatsapp.com/send?phone=9918233622' >
                          Contact now
                          </Link>
                        </Button>
                        
                      
                              
                     
                    </div>
               
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* modal */}
        {/* <Modal show={showModal} onHide={closeModal} centered size="lg" contentClassName="home-modal bg-transparent border-0" className="bd-example-modal-lg watchvideomodal" aria-hidden="true" fade>
          <Modal.Header closeButton className="border-0" closeVariant="white"></Modal.Header>
          <video id="VisaChipCardVideo" className="video-box w-100 h-100 pb-0" controls >
            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
          </video>
        </Modal> */}
      </section>
    </React.Fragment >
  )
}

export default Section;
